class carDeliveryComponent extends Component {

    static name() {
        return "carDeliveryComponent";
    }

    static componentName() {
        return "carDeliveryComponent";
    }

    getProps() {
        return  ['createNewDelivey'];
    }

    data() {
        return {
            Name:null,
            Country:null,
            LocalityCode:null,
            ZipCode:null,
            Address:null,
            City:null,
            ProvinceCode:null
        };
    }
    getComputed() {
        return {
            provinceFilter (){
                //console.log('Reload Province Filter');
                let filter = {};
                if( this.Country )
                    filter['Country'] = this.Country;
                return filter;
            },
            cityFilter (){
                let filter = {};
                if( this.ProvinceCode )
                    filter['Province'] = this.ProvinceCode;
                return null;
            },
            localityFilter (){
                let filter = {};
                if( this.ProvinceCode )
                    filter['ProvinceCode'] = this.ProvinceCode;
                return null;
            },
            zipCodeFilter (){
                let filter = {};
                if( this.LocalityCode )
                    filter['LocalityCode'] = this.LocalityCode;
                return null;
            },
            customer (){
                return this.$store.getters.getCustomer;
            },
            orderTemplate (){
                return this.$store.getters.getOrderTemplate;
            },
        };
    }

    getMethods() {
        return {
            updateSelectValues:this.updateSelectValues,
            closeModal: this.closeModal,
            saveRecord:this.saveRecord
        };
    }

    updateSelectValues(updateValue){
        this[updateValue] = event.target.value;
    }

    closeModal(){
        this.Name=null;
        this.Address=null;
        this.Country=null;
        this.LocalityCode=null;
        this.ZipCode=null;
        this.City=null;
        this.ProvinceCode=null;
        $("#newDeliveryModel").modal('hide');
    }

    saveRecord(){
        let self = this;
        this.createNewDelivey(this.$data).then((success)=>{
            if(success)
                self.closeModal();
        });
    }

    getTemplate() {
        return `<div class="modal" tabindex="-1" role="dialog" ref="newDeliveryModel" id="newDeliveryModel" >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">{{tr("Delivery Data")}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">{{tr("Name")}}</label>
                                <div class="col-sm-8">
                                   <input class="form-control" type="text" name="" v-model="Name">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">{{tr("Country")}}</label>
                                 <div class="col-sm-8">
                                    <selectLinkToComponent recordKey="modalDelivery" class="form-control" :table="'Country'" :paste="'Code'" :showField="'Name'" :selected="Country"  @update-value="updateSelectValues('Country')"> </selectLinkToComponent>
                                 </div>
                            </div>
                            <div class="form-group row ">
                                <label class="col-sm-4 col-form-label">{{tr("Province")}}</label>
                                <div class="col-sm-8">
                                    <selectLinkToComponent recordKey="modalDelivery" class="form-control" :table="'Province'" :paste="'Code'" :showField="'Name'" :filters="provinceFilter" :selected="ProvinceCode"  @update-value="updateSelectValues('ProvinceCode')"> </selectLinkToComponent>
                                </div>
                            </div>
                            <awesonLinkToComponent :noLinked="true" :formInline="true" recordKey="modalDelivery" :showLabel="true" label="City"     :table="'City'" :paste="'Code'" :showField="'Code'"  :filters="cityFilter" :selected="City" @update-value="updateSelectValues('City')"> </awesonLinkToComponent>
                            <awesonLinkToComponent :formInline="true" recordKey="modalDelivery" :showLabel="true" label="Locality" :table="'Locality'" :paste="'Code'"  :showField="'Description'" :selected="LocalityCode" :filters="localityFilter" @update-value="updateSelectValues('LocalityCode')"> </awesonLinkToComponent>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">{{tr("Zip Code")}}</label>
                                <div class="col-sm-8">
                                  <selectLinkToComponent class="form-control" :table="'ZipCode'" :paste="'Code'" :showField="'Code'" :filters="zipCodeFilter"  :selected="ZipCode" @update-value="updateSelectValues('ZipCode')" > </selectLinkToComponent>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group text-left ">
                                    <label class="col-12 col-form-label">{{tr("Delivery Address")}}</label>
                                    <div class="col-sm-9">
                                      <textarea class="form-control" rows="3" v-model="Address" ></textarea>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="closeModal" >{{tr('Close')}}</button>
                        <button type="button" class="btn btn-primary" v-on:click="saveRecord" >{{tr('Save')}}</button>
                      </div>
                    </div>
                  </div>
                </div>`;
    }
}

carDeliveryComponent.registerComponent();